/* Reset some basic elements for consistency across browsers */

/* global.css */

/* Style for the main application container */
.App {
  padding-bottom: 56px; /* Adjust this to match the height of your BottomNav */
  box-sizing: border-box; /* Ensures padding is included in total height */
}

/* Style for BottomNav */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px; /* Match this with your BottomNav height */
  z-index: 1000; /* Ensure it's above other content */
  background-color: #fff; /* Optional: adjust as needed */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure padding and borders are included in the element's total width and height */
}

html, body {
  height: 100%; /* Ensure body takes up full viewport height */
  font-family: 'Georgia', serif; /* Default font for the entire application */
  color: #3d0a02; /* Deep wine color for text */
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none; /* Remove underline from links by default */
  color: inherit; /* Inherit color from parent element */
}

button {
  border: none; /* Remove default button border */
  cursor: pointer; /* Pointer cursor for clickable buttons */
}

ul, ol {
  list-style: none; /* Remove default list styles */
}


input, textarea {
  border: 1px solid #8b3a3a; /* Burgundy border for inputs */
  border-radius: 8px; /* Rounded corners for inputs */
}

input:focus, textarea:focus {
  border-color: #6f2c2c; /* Darker shade on focus */
  outline: none; /* Remove default focus outline */
}

/* Utility classes for spacing */
.m-0 {
  margin: 0;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.p-20 {
  padding: 20px;
}

/* Centering utilities */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Flexbox utilities */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* Hide element utility */
.hidden {
  display: none;
}
